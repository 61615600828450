<template>
  <div class="container">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">{{ $t('selectVoice.title') }}</div>
    </div>
    <div class="core-container">
      <div v-if="voiceList.length > 0">
        <div class="scroll-wrapper" :style="{ height: height - 40 + 'px' }" ref="scroll">
          <div class="scroll-content">
            <div class="item-content" v-for="(item, index) in voiceList" :key="item.id">
              <div v-if="playingId === item.id && playStatus" class="item-index-playing">
                <img
                  src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/play.png"
                  alt=""
                />
              </div>
              <div v-else class="item-index">
                <div class="item-index-text">
                  {{ index + 1 }}
                </div>
              </div>
              <div class="item-text">
                <div class="item-text" @click="touch(item.id)">
                  <div :class="playingId === item.id && playStatus ? 'active' : ''">
                    <span v-if="language == 'zh'">{{ item.soundText }}</span>
                    <span v-else>{{ item.englishSoundText }}</span>
                  </div>
                  <hr class="item-line" v-if="index !== voiceList.length - 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data-content">
        <img
          class="no-data"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
          alt=""
        />
        <p class="no-data-text">{{ $t('noData') }}</p>
      </div>
    </div>
    <div class="stop-playing" v-if="playingId && playStatus && playType === 'loop'">
      <div class="stop-playing-button" @click="pauseSound">{{ $t('selectVoice.stopVoice') }}</div>
    </div>
    <div class="dialog" v-if="loopDialogFlag">
      <div class="dialog-cover"></div>
      <transition name="drop">
        <div class="dialog-content">
          <div class="dialog-head">
            <div>{{ $t('selectVoice.chooseVoice') }}</div>
          </div>
          <div class="loop_dialog_main">
            <div class="loop-button" @click="playTimes('loop')">{{ $t('selectVoice.loop') }}</div>
            <div class="once-button" @click="playTimes('once')">{{ $t('selectVoice.once') }}</div>
          </div>
          <div class="loop-dialog-operation">
            <div @click="loopCancel">{{ $t('cancel') }}</div>
          </div>
        </div>
      </transition>
    </div>
    <Dialog
      v-if="dialogFlag"
      :dialogContent="$t('selectVoice.confirmStopPlay')"
      :dialogTitle="$t('lightControl.prompt.title')"
      :dialogCancel="$t('cancel')"
      :dialogEnter="$t('confirm')"
      @handleOk="dialogEnter"
      @handleCancel="dialogCancel"
    >
    </Dialog>
  </div>
</template>

<script>
import { loadSounds, playSound, pauseSound } from '@/api/api';
import BScroll from 'better-scroll';
import Dialog from '@/components/Dialog.vue';

export default {
  name: 'choose',
  components: { Dialog },
  data() {
    return {
      dialogFlag: false,
      loopDialogFlag: false,
      playingId: null,
      playId: null,
      playStatus: false,
      playType: null,
      voiceList: [],
      language: this.$route.query.language || window.localStorage.getItem('language'),
    };
  },

  created: function () {
    loadSounds({}).then((res) => {
      this.voiceList = res;
      this.$loadingCircle.end();
      this.$nextTick(() => {
        this.init();
      });
    });
    this.height = window.innerHeight;
  },

  mounted: function () {
    this.vin = this.$route.query.vin;
    this.$loadingCircle.start();
    this.setLanguage(this.$route.query.language || window.localStorage.getItem('language'));
  },
  beforeDestroy() {
    this.bs.destroy();
  },

  methods: {
    setLanguage(language = 'zh') {
      this.$i18n.locale = language;
    },
    init() {
      this.bs = new BScroll(this.$refs.scroll, {
        probeType: 3,
        click: true,
      });
    },
    goback() {
      this.$router.push({
        path: `voice?vin=${this.$route.query.vin}&volume=${this.$route.query.volume}&cabinetType=${this.$route.query.cabinetType}&VinType=${this.$route.query.VinType}&language=${this.$route.query.language}`,
      });
    },
    touch(id) {
      this.playId = id;
      this.loopDialogFlag = true;
    },
    dialogCancel() {
      this.dialogFlag = false;
    },
    dialogEnter() {
      pauseSound({
        vin: this.vin,
      }).then((res) => {
        this.dialogFlag = false;
        this.playStatus = false;
        clearTimeout(this.timer);
        this.timer = null;
        this.$toast(this.$t('selectVoice.stopPlay'), { duration: 1500 });
      });
    },
    loopCancel() {
      this.loopDialogFlag = false;
    },
    pauseSound() {
      this.dialogFlag = true;
    },
    playTimes(times) {
      let playCount;
      if (times === 'once') {
        playCount = 1;
        this.playType = 'once';
      } else if (times === 'loop') {
        playCount = -1;
        this.playType = 'loop';
      }
      playSound({
        playCount: playCount,
        id: this.playId,
        vin: this.vin,
      }).then((res) => {
        this.playingId = this.playId;
        if (playCount === -1) {
          this.playStatus = true;
        } else {
          this.playStatus = true;
          this.timer = setTimeout(() => {
            this.playStatus = false;
          }, 10000);
        }
        this.$toast(this.$t('selectVoice.VoicePlayback'), { duration: 1500 });
        this.loopCancel();
      });
    },
  },
};
</script>

<style lang="scss">
.container {
  height: 100%;
  background: #ffffff;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 400;
  .core-container {
    margin-top: 40px;
    .scroll-wrapper {
      position: relative;
      overflow: hidden;
      background: #f7f7f7;
    }
  }
  .header {
    height: 40px;
    line-height: 40px;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
  }
  .back {
    width: 32px;
    height: 32px;
    margin: 4px 11px;
  }

  .title {
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin-right: 54px;
  }

  .item-content {
    display: flex;
    padding: 15px 20px 15px 10px;
  }
  .item-index {
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 600;
    width: 11px;
    text-align: center;
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  .item-index-text {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  .item-text {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 400;
    width: 100%;
    line-height: 30px;
    // word-break: break-all;
    white-space: pre-wrap;
  }
  .item-index-playing {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .item-index-playing img {
    width: 30px;
    height: 30px;
  }
  .item-line {
    height: 1px;
    width: 100%;
    background: #ebebeb;
    position: relative;
    top: 15px;
  }
  .active {
    color: #436eff;
  }
  .stop-playing {
    width: 100%;
    padding: 16px 40px;
    background: #ffffff;
    box-shadow: 0px -4px 8px 0px rgba(60, 64, 70, 0.1);
    position: fixed;
    bottom: 0;
  }
  .stop-playing-button {
    height: 48px;
    line-height: 48px;
    width: 100%;
    background: #436eff;
    border-radius: 46px;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 600;
  }
  .dialog {
    position: relative;
    color: #2e2c2d;
    font-size: 16px;
  }
  // 遮罩 设置背景层，z-index值要足够大确保能覆盖，高度 宽度设置满 做到全屏遮罩
  .dialog-cover {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  // 内容层 z-index要比遮罩大，否则会被遮盖，
  .dialog-content {
    position: fixed;
    bottom: 0;
    // 移动端使用felx布局
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 300;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    padding: 0px 40px 36px 40px;
  }
  .dialog-head {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #222222;
    letter-spacing: 0;
    text-align: center;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 18px;
  }
  .dialog_main {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #222222;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    height: 22px;
    line-height: 22px;
  }
  .dialog-operation {
    display: flex;
    padding-top: 24px;
    width: 100%;
  }
  .button-left {
    background: #ecedf2;
    border-radius: 48px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    width: 50%;
  }
  .button-right {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 600;
    background: #436eff;
    border-radius: 48px;
    height: 40px;
    line-height: 40px;
    width: 50%;
  }
  .button-center {
    width: 20px;
  }
  .loop-button {
    margin-bottom: 12px;
  }
  .loop-button,
  .once-button {
    background: #ffffff;
    border: 1px solid rgba(67, 110, 255, 1);
    border-radius: 8px;
    font-size: 16px;
    color: #436eff;
    letter-spacing: 0;
    font-weight: 600;
    height: 60px;
    line-height: 60px;
  }
  .loop_dialog_main {
    width: 100%;
    text-align: center;
  }
  .loop-dialog-operation {
    width: 100%;
    background: #ecedf2;
    border-radius: 24px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: 600;
    height: 46px;
    line-height: 46px;
    margin-top: 24px;
  }
  .no-data {
    width: 160px;
    height: 160px;
  }
  .no-data-text {
    width: 100%;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #222222;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
  .no-data-content {
    text-align: center;
    position: relative;
    top: 160px;
    background: #ffffff;
    height: 100%;
  }
}
</style>
